import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { RoutePaths } from 'src/core/router/RoutePaths';
import LogoImage from 'src/icons/rfhl-logo.png';
import styled from 'styled-components';

const StyledWrapper = styled(Link)`
    color: #0d0f1c;
    display: flex;
    margin-right: 85px;

    &:hover {
        text-decoration: none;
        color: #0d0f1c;
    }
`;

export const Logo = observer(() => {
    return (
        <StyledWrapper to={RoutePaths.index}>
            <SLogo src={LogoImage} />
        </StyledWrapper>
    );
});

const SLogo = styled.img`
    height: 1.5rem;
`;
